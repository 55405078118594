const cookieData = `[
    {
      "lang": "pt-br",
      "allOptOut": false,
      "acceptButton": "Aceitar",
      "optOutButton": "Definir Cookies",
      "optInButton": "Ver Política de Cookies",
      "infoText": "Nós usamos cookies para melhorar sua experiência de navegação no portal. Ao utilizar o cct.ufcg.edu.br, você concorda com a política de monitoramento de cookies. Para ter mais informações sobre como isso é feito, acesse Ver Política de Cookies. Se você concorda, clique em Aceito.",
      "mainTitle": "Política de Cookies",
      "lastUpdate": "21/03/2022",
      "entryText": "O CCT preza pela transparência em nossa política de cookies, veja abaixo a relação dos cookies utilizados no nosso portal.",
      "selectAll": false,
      "allAlertMessage": "Ao desativar essa opção, sua navegação ficará comprometida",
      "closeLabel": "fechar",
      "lastUpdateLabel": "Última atualização",
      "cookieGroupsLabel": "Classes de cookies",
      "selectAllLabel": "Selecionar tudo",
      "unselectAllLabel": "Desselecionar tudo",
      "selectAllGroupLabel": "Selecionar toda classe",
      "unselectAllGroupLabel": "Desselecionar toda classe",
      "onLabel": "Ligado",
      "offLabel": "Desligado",
      "alwaysActiveLabel": "Sempre ativo",
      "cookieNameLabel": "Cookies",
      "expiresLabel": "Vencimento",
      "domainLabel": "Domínio",
      "enterpriseLabel": "Empresa",
      "purposeLabel": "Finalidade",
      "descriptionLabel": "Descrição",
      "cookieGroups": [
        {
          "groupId": "necessarios",
          "groupName": "Estritamente necessários",
          "groupOptOut": false,
          "groupSelected": false,
          "groupText": "Grupo de cookies extritamente necessários para o funcionamento do site.",
          "cookieList": [
            {
              "cookieId": "cookiesAceite",
              "cookieOptOut": false,
              "cookieSelected": false,
              "cookieName": "cookiesAceite",
              "expires": "10 anos",
              "domain": "cct.ufcg.edu.br",
              "entreprise": "CCT-UFCG",
              "purpose": "Autorização",
              "description": "Indica se o usuário aceitou esta política de cookies."
            }
          ]
        },
        {
          "groupId": "experiencia",
          "groupName": "Experiência do site",
          "groupOptOut": true,
          "groupSelected": false,
          "groupText": "Cookies utilizados para melhor experiência do site.",
          "cookieList": [
            {
              "cookieId": "wp_lang",
              "cookieOptOut": false,
              "cookieSelected": false,
              "cookieName": "wp_lang",
              "expires": "Sessão",
              "domain": "cct.ufcg.edu.br",
              "entreprise": "CCT-UFCG",
              "purpose": "Autorização",
              "description": "Idioma do usuário."
            },
            {
              "cookieId": "wordpress_test_cookie",
              "cookieOptOut": false,
              "cookieSelected": false,
              "cookieName": "wordpress_test_cookie",
              "expires": "Sessão",
              "domain": "cct.ufcg.edu.br",
              "entreprise": "CCT-UFCG",
              "purpose": "Autorização",
              "description": "Teste de cookie."
            }
          ]
        }
      ],
      "noteTitle": "Aviso sobre cookies",
      "noteList": [
        {
          "question": "O que são cookies?",
          "answer": "Cookies são arquivos salvos em seu computador, tablet ou telefone quando você visita um site. Usamos os cookies necessários para fazer o site funcionar da melhor forma possível e sempre aprimorar os nossos serviços."
        },
        {
          "question": "Durabilidade?",
          "answer": "Todos os cookies têm uma data de vencimento e são descartados após ela."
        },
        {
          "question": "Finalidade?",
          "answer": "Os cookies são utilizados para guardar informações no navegador do usuário. Como por exemplo, marcar que esta política já foi aceita, evitando exibí-la repetidas vezes ao usuário."
        }
      ],
      "links": [
        {
          "name": "Declaração de Conformidade com os Princípios de Proteção de Dados",
          "url": "#"
        }
      ]
    }
  ]`;
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}
if(!getCookie('cookieAceite')){
  dsgov.BRCookiebar.createCookiebar(cookieData, (data) => {
    var date = new Date();
    date.setTime(date.getTime() + 315360000000); // 10 anos
    document.cookie =
      "cookieAceite=true;expires=" + date.toUTCString() + "; path=/";
  });
}