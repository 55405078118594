
// Init Menu
const menuList = []
for (const brMenu of window.document.querySelectorAll('.br-menu')) {
  menuList.push(new dsgov.BRMenu('br-menu', brMenu))
}

// Init Tab
const abasList = []
for (const brTab of window.document.querySelectorAll('.br-tab')) {
  abasList.push(new dsgov.BRTab('br-tab', brTab))
}

// Init Breadcrumb
const breadcrumbList = []
for (const brBreadcrumb of window.document.querySelectorAll('.br-breadcrumb')) {
  breadcrumbList.push(new dsgov.BRBreadcrumb('br-breadcrumb', brBreadcrumb))
}

// Init card
const listCard = []
for (const [index, brCard] of window.document
  .querySelectorAll('.br-card')
  .entries()) {
  listCard.push(new dsgov.BRCard('br-card', brCard, index))
}

// Init carousel
const carouselList = []
for (const brCarousel of window.document.querySelectorAll('.br-carousel')) {
  carouselList.push(new dsgov.BRCarousel('br-carousel', brCarousel))
}

// Init Checkbox
const checkboxList = []
for (const brCheckbox of window.document.querySelectorAll('.br-checkbox')) {
  checkboxList.push(new dsgov.BRCheckbox('br-checkbox', brCheckbox))
}

// Init DateTimePicker
const datetimepickerList = []
for (const brDateTimePicker of window.document.querySelectorAll(
  '.br-datetimepicker'
)) {
  datetimepickerList.push(
    new dsgov.BRDateTimePicker('br-datetimepicker', brDateTimePicker)
  )
}

// Init Footer
const listFooter = []
for (const brFooter of window.document.querySelectorAll('.br-footer')) {
  listFooter.push(new dsgov.BRFooter('br-footer', brFooter))
}

// Init Header
const headerList = []
for (const brHeader of window.document.querySelectorAll('.br-header')) {
  headerList.push(new dsgov.BRHeader('br-header', brHeader))
}

// Init Input
const inputList = []
for (const brInput of window.document.querySelectorAll('.br-input')) {
  inputList.push(new dsgov.BRInput('br-input', brInput))
}

// Init Item
const itemList = []
for (const brItem of window.document.querySelectorAll('.br-item')) {
  itemList.push(new dsgov.BRItem('br-item', brItem))
}

// Init List
const listList = []
for (const brList of window.document.querySelectorAll(('.br-list'))) {
  listList.push(new dsgov.BRList('br-list', brList))
}

// Init Message
const alertList = []
for (const brAlert of window.document.querySelectorAll('.br-message')) {
  alertList.push(new dsgov.BRAlert('br-message', brAlert))
}

// Init Modal
const modalList = []
for (const brModal of window.document.querySelectorAll('.br-modal')) {
  modalList.push(new dsgov.BRModal('br-modal', brModal))
}

// Init Notification
const notificationList = []
for (const brNotification of window.document.querySelectorAll(
  '.br-notification'
)) {
  notificationList.push(new dsgov.BRNotification('br-notification', brNotification))
}

// Init Pagination
const paginationList = []
for (const brPagination of window.document.querySelectorAll('.br-pagination')) {
  paginationList.push(new dsgov.BRPagination('br-pagination', brPagination))
}

// Init Select
const selectList = []
for (const brSelect of window.document.querySelectorAll('.br-select')) {
  selectList.push(new dsgov.BRSelect('br-select', brSelect))
}

// Init Step
const stepList = []
for (const brStep of window.document.querySelectorAll('.br-step')) {
  stepList.push(new dsgov.BRStep('br-step', brStep))
}

// Init Table
const tableList = []
for (const [index, brTable] of window.document
  .querySelectorAll('.br-table')
  .entries()) {
  tableList.push(new dsgov.BRTable('br-table', brTable, index))
}

// Init Tag
const tagList = []
for (const brTag of window.document.querySelectorAll('.br-tag')) {
  tagList.push(new dsgov.BRTag('br-tag', brTag))
}

// Init Textarea
const textareaList = []
for (const brTextArea of window.document.querySelectorAll('.br-textarea')) {
  textareaList.push(new dsgov.BRTextArea('br-textarea', brTextArea))
}

// Init Wizard
const wizardList = []
for (const brWizard of window.document.querySelectorAll('.br-wizard')) {
  wizardList.push(new dsgov.BRWizard('br-wizard', brWizard))
}